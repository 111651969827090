import { Alert, Snackbar } from "@mui/material";
import { ErrorObject } from "./ErrorObject";
import { FC } from "react";

type Props = {
  errorObj: ErrorObject;
  onClose: () => void;
};
export const ErrorSnackBar: FC<Props> = ({ errorObj, onClose }) => {
  return (
    <Snackbar open={errorObj.error}>
      <Alert severity="warning" onClose={onClose}>
        エラーが発生しました({errorObj.message ?? "不明なエラー"})
      </Alert>
    </Snackbar>
  );
};
