import { ApiRequestError } from "./ApiRequestError";
import { CallApi } from "./ApiCommon";
import { babyDomain } from "./Config";

export const PostLike = async (
  serverName: string,
  babyId: string,
  answerId: string
) => {
  try {
    await CallApi(`https://${babyDomain}/api_baby/${babyId}/like`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ id: answerId }),
    });
  } catch (e: any) {
    throw new ApiRequestError(e?.message);
  }
};
