import { BabySetting } from "../response/BabySetting";

export const getSetting = async (
  serverName: string,
  babyId: string
): Promise<BabySetting> => {
  const result = await fetch(
    `https://dev.${serverName}.ai-saloon.com/api_baby/${babyId}/baby`
  );
  return (await result.json()).baby as BabySetting;
};
