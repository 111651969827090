import styled from "styled-components";
import { Link, styled as styledM } from "@mui/material";

const Footer = styled.div`
  color: #fff;
  background-color: #555;
  height: 80px;
  padding: 8px;
  font-size: 0.9em;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterRow = styled.div`
  padding: 0 8px;
  text-align: left;
`;

const WhiteLink = styledM(Link)(
  () => `
    color: #fff;
  `
);

export const BaseFooter = () => {
  return (
    <Footer>
      <FooterRow>
        <p>
          <WhiteLink href={"https://ai-saloon.com/pages/terms"}>
            {" "}
            利用規約
          </WhiteLink>
        </p>
        <p>
          <WhiteLink href={"https://ai-saloon.com/about"}>
            {" "}
            ai広場について
          </WhiteLink>
        </p>
      </FooterRow>
      <FooterRow>
        <p>
          <WhiteLink href={"/banshee/2001/"}>
            サンプルベイビー(更新情報)
          </WhiteLink>
        </p>
        <p>
          <WhiteLink href={"https://ai-saloon.com/inquiry"}>
            メールフォーム
          </WhiteLink>
        </p>
      </FooterRow>
    </Footer>
  );
};
