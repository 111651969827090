import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { createGlobalStyle } from "styled-components";
import SendIcon from "@mui/icons-material/Send";
import { AppContext } from "../Baby";
import { GetImageUrl } from "../util/Image";
import { MenuContainer } from "./MenuContainer";
import { UserContents } from "./UserContents";
import { getAnswer } from "../api/GetAnswer";
import { getSetting } from "../api/GetSetting";
import { getDisplay } from "../api/GetDisplay";
import { ErrorSnackBar } from "./error/ErrorSnackBar";
import { NotificationSnackBar } from "./NotificationSnackBar";
import { AnswerMenuContainer } from "./AnswerMenuContainer";

type Props = {
  babyId: string;
  serverName: string;
};

export const BabyMain: FC<Props> = ({ babyId, serverName }) => {
  const [message, setMessage] = useState<string>("");
  const { state, dispatch } = useContext(AppContext);

  const initialize = async () => {
    const setting = await getSetting(serverName, babyId);
    dispatch({ type: "updateBabySetting", payload: setting });

    const answer = await getAnswer(serverName, babyId, message);
    dispatch({ type: "updateAnswer", payload: answer });

    if (setting.pcDisplayId.length > 0) {
      const display = await getDisplay(setting.pcDisplayId);
      dispatch({ type: "updateDisplay", payload: display });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleOnClick = async () => {
    const answer = await getAnswer(serverName, babyId, message);
    dispatch({ type: "updateAnswer", payload: answer });
  };

  const closeError = () => {
    dispatch({ type: "setError", payload: { error: false, message: "" } });
  };

  const closeNotification = () => {
    dispatch({ type: "setNotification" });
  };

  const color = useMemo(() => {
    return "#ffffff";
  }, []);

  const GlobalStyles = createGlobalStyle`
    html {
      --answer-bg-color: ${color} !important;
    }
  `;

  return (
    <div id="baby_root_container">
      <GlobalStyles />
      {state.notification && (
        <NotificationSnackBar
          message={state.notification}
          onClose={closeNotification}
        />
      )}
      {state.error && (
        <ErrorSnackBar errorObj={state.error} onClose={closeError} />
      )}
      {state.display?.header_html && (
        <UserContents html={state.display.header_html} />
      )}
      <div id="baby_image_container">
        {state?.answer && (
          <div id="baby_image">
            <img src={GetImageUrl(babyId, state.answer.picture)} alt="" />
          </div>
        )}
      </div>
      <div id="name_x_container">
        <div id="baby_name_container">
          <div id="name_box">{state?.babySetting?.name}</div>
        </div>
        <div id="baby_x_container">
          {state?.babySetting?.xPrefix}
          {state?.babySetting?.x}
          {state?.babySetting?.xSuffix}
        </div>
      </div>
      <div id="baby_answer_container">{state?.answer?.text}</div>
      <AnswerMenuContainer
        babyId={babyId}
        server={serverName}
        ratingText={state?.babySetting?.ratingText ?? ""}
      />

      <div id="input_container">
        <input
          type="text"
          onChange={(event) => {
            setMessage(event.target.value);
          }}
        />
        <button onClick={handleOnClick}>
          <SendIcon fontSize={"small"} />
        </button>
      </div>

      {state?.babySetting && (
        <MenuContainer
          babyId={babyId}
          server={serverName}
          babySetting={state?.babySetting}
        />
      )}

      {state.display?.footer_html && (
        <UserContents html={state.display.footer_html} />
      )}
    </div>
  );
};
