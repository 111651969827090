import { Answer } from "../response/Answer";

export const getAnswer = async (
  serverName: string,
  babyId: string,
  message: string
): Promise<Answer> => {
  const result = await fetch(
    `https://dev.${serverName}.ai-saloon.com/api_baby/${babyId}/answer?text=${message}&ref=`
  );
  return (await result.json()).answer as Answer;
};
