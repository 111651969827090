import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Tooltip } from "@mui/material";
import { PostLike } from "../api/LikeApi";
import { FC, useContext } from "react";
import { AppContext } from "../Baby";

type Props = {
  babyId: string;
  server: string;
  ratingText: string;
};
export const AnswerMenuContainer: FC<Props> = ({
  babyId,
  server,
  ratingText,
}) => {
  const { state, dispatch } = useContext(AppContext);

  const handleLike = async () => {
    if (state.answer?.ID == null) return;
    try {
      await PostLike(server, babyId, state.answer.ansID);
      dispatch({
        type: "setNotification",
        payload: "",
      });
      dispatch({
        type: "setNotification",
        payload: "この返答に「いいね」しました",
      });
    } catch (e: any) {
      dispatch({
        type: "setError",
        payload: { error: true, message: e.message },
      });
    }
  };
  return (
    <div id="answer_menu_container">
      <Tooltip title="この返答にいいねする">
        <div onClick={handleLike} id="like_button">
          <div>
            <ThumbUpOffAltIcon />
            {ratingText}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
