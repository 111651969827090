import { useParams } from "react-router-dom";
import React, { createContext, Dispatch, useReducer } from "react";
import {
  ActionProps,
  initialState,
  reducer,
  StateProps,
} from "./hooks/BabyReducer";
import { Helmet } from "react-helmet-async";
import { BabyMain } from "./components/BabyMain";
import { BaseFooter } from "./components/BaseFooter";
import { Box, styled } from "@mui/material";

export const AppContext = createContext(
  {} as { state: StateProps; dispatch: Dispatch<ActionProps> }
);

const ContentsBox = styled(Box)(
  () => `
  display:flex;
  flex-flow: column;
  min-height: 100vh;
  `
);

const MainBox = styled(Box)(
  () => `
  flex: 1;
  `
);

export const Baby = () => {
  const { babyId, serverName } =
    useParams<{ babyId: string; serverName: string }>();

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ContentsBox>
        {state.babySetting != null && (
          <Helmet>
            <link rel="stylesheet" href={"/css/default.css"} />
            {state.display && (
              <style type="text/css">{state.display.css}</style>
            )}
            <title>{state.babySetting.title} - ai広場</title>
          </Helmet>
        )}
        <MainBox>
          {babyId && serverName && (
            <BabyMain babyId={babyId} serverName={serverName} />
          )}
        </MainBox>
        <BaseFooter />
      </ContentsBox>
    </AppContext.Provider>
  );
};
