import { FC } from "react";
import { Alert, Snackbar } from "@mui/material";

type Props = {
  message?: string;
  onClose: () => void;
};
export const NotificationSnackBar: FC<Props> = ({ message, onClose }) => {
  return (
    <Snackbar open={message != null} autoHideDuration={5000} onClose={onClose}>
      <Alert severity="success" onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
