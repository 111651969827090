import { ApiRequestError } from "./ApiRequestError";

export const CallApi = async (url: string, request: RequestInit) => {
  const response = await fetch(url, request);
  if (!response.ok) {
    const message = (await response.json())?.message;
    throw new ApiRequestError(message);
  }
  return await response.json();
};
