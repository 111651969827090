import { Reducer } from "react";
import { BabySetting } from "../response/BabySetting";
import { Answer } from "../response/Answer";
import {
  SetError,
  SetFavorite,
  SetNotification,
  UpdateAnswer,
  UpdateBabySetting,
  UpdateDisplay,
} from "./BabySetting";
import { Display } from "../response/Display";
import { ErrorObject } from "../components/error/ErrorObject";
import { FavoriteItem } from "../response/FavoriteItem";

export interface StateProps {
  babySetting?: BabySetting;
  answer?: Answer;
  display?: Display;
  favoriteItem: FavoriteItem | null;
  error?: ErrorObject;
  notification?: string;
}

export type ActionProps =
  | UpdateBabySetting
  | UpdateAnswer
  | UpdateDisplay
  | SetError
  | SetFavorite
  | SetNotification;
export const initialState: StateProps = {
  babySetting: undefined,
  favoriteItem: null,
};

export const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case "updateBabySetting":
      return {
        ...state,
        babySetting: action.payload,
      };
    case "updateAnswer":
      return {
        ...state,
        answer: action.payload,
      };
    case "updateDisplay":
      return {
        ...state,
        display: action.payload,
      };
    case "setError":
      return {
        ...state,
        error: action.payload,
      };
    case "setFavorite":
      return {
        ...state,
        favoriteItem: action.payload,
      };
    case "setNotification":
      return {
        ...state,
        notification: action.payload,
      };
    default:
      throw new Error();
  }
};
