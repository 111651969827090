import { homeDomain } from "./Config";
import { FavoriteItem } from "../response/FavoriteItem";
import { ApiRequestError } from "./ApiRequestError";
import { CallApi } from "./ApiCommon";

export const GetFavorite = async (): Promise<FavoriteItem[]> => {
  try {
    const result = await CallApi(`https://${homeDomain}/api_user/favorite`, {
      credentials: "include",
    });
    return result.data as FavoriteItem[];
  } catch (e: any) {
    throw new ApiRequestError(e?.message);
  }
};

export const PostFavorite = async (
  serverName: string,
  babyId: string
): Promise<FavoriteItem> => {
  try {
    const result = await CallApi(`https://${homeDomain}/api_user/favorite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        baby_id: babyId,
        server: serverName,
      }),
    });
    return result.data as FavoriteItem;
  } catch (e: any) {
    throw new ApiRequestError(e?.message);
  }
};

export const DeleteFavorite = async (favoriteId: string) => {
  try {
    await CallApi(`https://${homeDomain}/api_user/favorite?id=${favoriteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e: any) {
    throw new ApiRequestError(e?.message);
  }
};
