import React, { FC, useContext, useEffect } from "react";
import { BabySetting } from "../response/BabySetting";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SaveIcon from "@mui/icons-material/Save";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Tooltip } from "@mui/material";
import { DeleteFavorite, GetFavorite, PostFavorite } from "../api/FavoriteApi";
import { AppContext } from "../Baby";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

type Props = {
  babyId: string;
  server: string;
  babySetting: BabySetting;
};
export const MenuContainer: FC<Props> = ({ babyId, server, babySetting }) => {
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    InitializeFavorite();
  }, []);

  const InitializeFavorite = async () => {
    const favorites = await GetFavorite();
    const favoriteItem = favorites.find(
      (item) => item.baby_ID.toString() === babyId && item.server === server
    );
    dispatch({ type: "setFavorite", payload: favoriteItem ?? null });
  };

  const handleFavorite = async () => {
    try {
      if (state.favoriteItem) {
        await DeleteFavorite(state.favoriteItem.ID);
        dispatch({ type: "setFavorite", payload: null });
        dispatch({
          type: "setNotification",
          payload: "お気に入りから削除しました",
        });
      } else {
        const response = await PostFavorite(server, babyId);
        dispatch({ type: "setFavorite", payload: response });
        dispatch({
          type: "setNotification",
          payload: "お気に入りに追加しました",
        });
      }
    } catch (e: any) {
      dispatch({
        type: "setError",
        payload: { error: true, message: e.message },
      });
    }
  };
  return (
    <div id="menu_container">
      <div id="baby_title">{babySetting.title}</div>
      <div id="user_menu_container">
        <p className="menu_icon" onClick={handleFavorite}>
          {state.favoriteItem ? (
            <Tooltip title="お気に入りから解除">
              <FavoriteIcon />
            </Tooltip>
          ) : (
            <Tooltip title="お気に入りに登録">
              <FavoriteBorderIcon />
            </Tooltip>
          )}
        </p>
        <p className="menu_icon">
          <Tooltip title="セーブデータを確認">
            <SaveIcon />
          </Tooltip>
        </p>
        <p className="menu_icon">
          <Tooltip title="スマートフォン表示">
            <SmartphoneIcon />
          </Tooltip>
        </p>
      </div>
    </div>
  );
};
